import type { ResultWithData } from "~/utils/storeUtils"

export const useItemCreator = <TItem extends WithId>(baseUrl: ItemStoreUrl, onCreatedCallback: (item: TItem) => any, errorCallback: (error: ActionError) => any) => {
    const createItemAsync = async (item: Omit<TItem, "id">) : Promise<ResultWithData<TItem>> => {
        try {
            const createdItem = (await $fetch(String(baseUrl), {
                method: "POST",
                body: item,
                onResponseError: createDefaultErrorHandler(errorCallback)
            })) as TItem
            if (!!!createdItem) {
                return {
                    success: false,
                    message: "Itel could not be created"
                }
            }
            onCreatedCallback(createdItem)
            return {
                success: true,
                data: createdItem,
            }
        } catch (error) {
            console.error("Could not create item: " + error)
            return {
                success: false,
                message: String(error),
            }
        }
    }

    return {
        createItemAsync
    }
}